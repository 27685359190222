import { IContentElement } from "../models";
import { IElementTypeEditInfo } from './../models/index'

export interface IContentElementMath extends IContentElement {
    latex: string;
    paragraphStyle: string;
  }

  export const mathEditInfo: IElementTypeEditInfo = {
    editExcludeFields: [],
    editTextFields: ['latex'],
    editKeyFieldsToShow: ['latex', 'paragraphStyle'],
    nonHiddenChangeLogFields: ['latex', 'paragraphStyle']
  }