import { IElementTypeEditInfo } from './../models/index'

export enum BookmarkType {
    NORMAL = "normal",
    IMAGE = "image"
  }

export const bookmarkLinkEditInfo = {
  editExcludeFields: [],
  editTextFields: ['caption']
}