<div class="element-type-table">
  <table style="width:auto; line-height: normal;" *ngIf="element && element.grid">
    <tr *ngFor="let row of element.grid; let i = index;"  [class.header-row]="checkHeaderByIndex(i)">
      <td
              *ngFor="let cell of row; let col_i = index;"
              [class.header-col]="checkColHeaderByIndex(col_i)"
              [class.is-selected-edit]="editSelection.isSelectedEdit(cell, questionPubSub)"
              [style.border]="editSelection.getBorder(cell, questionPubSub)"
              [ngStyle]="{
        'text-align':parseCellAlignment(cell.align),
        'width.em':getColWidth(col_i)
      }"
      >
        <div [ngSwitch]="cell.elementType">
          <div *ngSwitchCase="'text'"   class="inline-block">            <element-render-text      [element]="cell" [questionState]="questionState"  ></element-render-text></div>
          <div *ngSwitchCase="'math'"   class="inline-block is-math">    <element-render-math      [element]="cell" [questionState]="questionState" [isInline]="true"  ></element-render-math></div>
          <div *ngSwitchCase="'image'"  class="inline-block">            <element-render-image     [element]="cell" [questionState]="questionState" ></element-render-image></div>
          <div *ngSwitchDefault class="element-text">
            <render-highlightable
            [inputString]="cell.val"
            [entryId]="cell.entryId"
            [prop]="'val'"
            >
              <markdown-inline [input]="cell.val"></markdown-inline>
            </render-highlightable>
          </div>
        </div>
      </td>
    </tr>
  </table>
</div>