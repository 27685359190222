import { IContentElement, IEntryStateScored } from "../models";
import { IElementTypeEditInfo } from './../models/index'

export interface IContentElementGraphing extends IContentElement {
    availableTools: { [key: string]: boolean };
}

export interface IEntryStateGraphing extends IEntryStateScored {
    state: any;
}

export const graphingEditInfo: IElementTypeEditInfo = {
    editExcludeFields: []
}

