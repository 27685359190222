import { IContentElement } from "../models";
import { IQPubSubPayload } from "../question-runner/pubsub/question-pubsub";
import { IElementTypeEditInfo } from './../models/index'

export interface IContentElementAudio extends IContentElement {
  url?: string;
  fileType?: string;
  altText?: string;
  numAudioPlays?: number;
  isLimitAudioPlays?: boolean;
  isAutoPlay?: boolean;
  isHidden?: boolean;
  isPermanentTooltip?: boolean;
  toolTipPosition?: ToolTipPositions;
  autoPlayDelay?: number;
  onAudioEnd?: IQPubSubPayload[];
  labelX?: number;
  labelY?: number;
  buttonScale?: number;
  displayMode?: DisplayMode;
}

export enum ToolTipPositions {
  TOP = "TOP",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  BOTTOM = "BOTTOM"
}

export enum DisplayMode {
  NORMAL = "normal",
  CUSTOM = "custom"
}

export const audioEditInfo: IElementTypeEditInfo = {
  nonHiddenChangeLogFields: ['altText', 'url'],
  editExcludeFields: []
}