import { Component, Input, Output, EventEmitter, OnDestroy, OnInit, ViewEncapsulation, ElementRef, HostListener, ViewChild } from '@angular/core';
import * as Diff from 'diff';
import { ElementType, IContentElement } from '../../ui-testrunner/models';
import { AuthScopeSetting, AuthScopeSettingsService } from '../auth-scope-settings.service';
import { EditingDisabledService } from '../editing-disabled.service';
import { ItemBankSaveLoadCtrl } from '../item-set-editor/controllers/save-load';
import { EditType, ItemComponentEditService } from '../item-component-edit.service';
import { getElementChildren } from '../item-set-editor/models';
import { TextDiffService } from '../text-diff.service';
import { AuthService } from "../../api/auth.service";
import { DEFAULT_VOICEOVER_PROP } from 'src/app/ui-item-maker/element-config-mcq-option-info/element-config-mcq-option-info.component';
import * as _ from 'lodash';
import { scoreMatrixElementTypes } from "./../config-score-matrix/models"

interface EditField {
  prop: string,
  content: any,
  timestamp?: any,
  path?: string[],
  textDiff?: any[]
}

@Component({
  selector: 'element-config',
  templateUrl: './element-config.component.html',
  styleUrls: ['./element-config.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ElementConfigComponent implements OnInit, OnDestroy {

  @Input() contentElement: IContentElement;
  @Input() isEditing: boolean;
  @Input() parentEditType: EditType;

  @Output() scoredMatrixConfirmed = new EventEmitter<boolean>();
  @Output() hasEditOrReviewContentSignal = new EventEmitter();
  @Output() showInAddOrDeleteSignal = new EventEmitter();

  editType: EditType = EditType.NONE;
  inheritedEditType: boolean = false;
  timestamp: string;
  ElementType = ElementType;
  fieldSelected;
  isAdded = false;
  editStatus = false;
  displayEditFields:EditField[] = [];
  subElements = [];
  hasEditOrReviewContent:boolean;
  isShowInAddOrDelete:boolean;
  suggestionVersionsMap = new Map();

  EditType = EditType;
  refreshAllChangesSub;
  scoreMatrixElementTypes = scoreMatrixElementTypes;

  constructor(public editingDisabled: EditingDisabledService,
    private itemComponentEditService: ItemComponentEditService,
    private textDiffService: TextDiffService,
    private authScopeSettings: AuthScopeSettingsService,
    private auth: AuthService
  ) { }


  ngOnInit() {
    if (this.isEditing) {
      this.editingInit();
    }
  }

  ngOnDestroy() {
    if(this.refreshAllChangesSub) {
      this.refreshAllChangesSub.unsubscribe();
    }
    // If a config diff is being removed, reset the flag to show "Accept all changes", so it stays off unless diffs in other blocks set it back
    this.itemComponentEditService.hasDiffsToAcceptAll = false;
  }

  editingInit() {

    this.refreshAllChangesSub = this.itemComponentEditService.refreshAllChangesSub.subscribe(() => {
      this.refreshChanges();
    })
    this.refreshChanges();
    this.getSubElements();
  }

  

  getSubElements() {
    this.subElements = getElementChildren(this.contentElement, {includeSolution: true, includeDnDMoveableImages: true, includeDnDTargets: true, includeVoiceover: true});
  }

  select() {
    this.itemComponentEditService.selectedEntry = {
      id: this.contentElement.entryId,
      border: this.getBorder()
    }
  }

  getBorder(){
    return this.itemComponentEditService.getBorder(this.editType, this.displayEditFields)
  }


  isSelected() {
    return this.itemComponentEditService.selectedEntry?.id && this.itemComponentEditService.selectedEntry?.id === this.contentElement.entryId;
  }

  deselect() {
    this.itemComponentEditService.selectedEntry = null;
  }

  toggleSelect() {
    if(this.isSelected()) {
      this.deselect();
    } else {
      if (this.hasChanges()) {
        this.select();
      }
    }

    if(this.usingEditingMode()) {
      this.itemComponentEditService.refreshDiffSub.next();
      this.itemComponentEditService.refreshAllChangesSub.next();
    }
  }

  onClickValue(field) {
    if (this.hasChanges() && this.editType === EditType.EDITED) {
      this.editStatus = !this.editStatus;
      this.fieldSelected = field;
    }

    this.select();
  }

  // Accepts the diff by calling the correct service method
  onClickAccept() {

    if (this.editType === EditType.ADDED) {
      this.itemComponentEditService.acceptNewEl(this.contentElement);
    } else if (this.editType === EditType.DELETED) {
      this.itemComponentEditService.acceptDeleteEl(this.contentElement);
    } else {
      this.itemComponentEditService.acceptDiff(this.contentElement.entryId, this.fieldSelected)
      const newFilter = this.displayEditFields.filter((field) => field[0] !== this.fieldSelected);
      this.displayEditFields = newFilter;

    }

    // Reset editing highlight
    this.editStatus = !this.editStatus;
    this.deselect();
    this.fieldSelected = "";
    this.refreshChanges();
  }

  // Rejects  the diff by calling the correct service method
  onClickReject() {

    if (this.editType === EditType.ADDED) {
      this.itemComponentEditService.rejectNewEl(this.contentElement);
    } else if (this.editType === EditType.DELETED) {
      this.itemComponentEditService.rejectDeleteEl(this.contentElement);
    } else {
      this.itemComponentEditService.rejectDiff(this.contentElement, this.fieldSelected)
      const newFilter = this.displayEditFields.filter((field) => field[0] !== this.fieldSelected);
      this.displayEditFields = newFilter;
    }

    // Reset editing highlight
    this.editStatus = !this.editStatus;
    this.deselect();
    this.fieldSelected = "";
    this.refreshChanges();
  }

  isTextDiffProp(prop) {
    return this.itemComponentEditService.isTextDiffProp(prop, this.contentElement);
  }


  isKeyFieldToShowProp(prop){
    return this.itemComponentEditService.isKeyFieldToShowProp(prop, this.contentElement);
  }

  isNoKeyFieldsOverrideElement(){
    return this.itemComponentEditService.isNoKeyFieldsOverrideElement(this.contentElement);
  }

  hasChanges() {
    const hasChanges = this.itemComponentEditService.usingEditingMode() ? !!this.displayEditFields?.length : this.editType !== EditType.NONE
    if (hasChanges) this.handleHasEditOrReviewContent()
    return hasChanges
  }

  // Edit Type Checker
  // Checks for edits and sets the edit type for this instance of an edit
  refreshEditType() {
    this.inheritedEditType = false;
    if(this.parentEditType === EditType.ADDED || this.parentEditType === EditType.DELETED) {
      this.editType = this.parentEditType;
      this.inheritedEditType = true;
      return;
    }
  }

  // Styling functions
  // Adds styling based on the type of the edit
  getFieldColor() {
    return this.itemComponentEditService.getBorderColour(this.editType, this.displayEditFields);
  }

  getValueColor() {
    if (this.editType === EditType.ADDED) {
      return  "#71CE69";
    } else if (this.editType === EditType.DELETED) {
      return  "#cc0000";
    } else {
      return  "transparent";
    }    
  }

  refreshChanges() {
    this.fieldChange();
    this.refreshEditType();
    this.updateChangeCounter(); //Proper rendering of some element types (e.g. grouping) requires a changeCounter increment from the config. Do it here 
  }

  updateChangeCounter() {
    if (!this.contentElement._changeCounter) {
      this.contentElement._changeCounter = 0;
    }
    this.contentElement._changeCounter ++;
    //Also update the suggestion, not just the copy. Find the same element by entryId.
    const suggestionElement = this.itemComponentEditService.deepFind(this.itemComponentEditService.suggestion?.state?.content, 'entryId', this.contentElement.entryId);
    if (suggestionElement) suggestionElement._changeCounter = this.contentElement._changeCounter;
  }

  getAllTextFields() {
    return Object.keys(this.contentElement).filter((prop) => {
      return this.isTextDiffProp(prop);
    }).map( prop => { 
      return {
        prop, 
        content: this.contentElement[prop]
      }});
  }

  getAllViewableFields() {
    return Object.keys(this.contentElement).filter((prop) => {
      return this.isViewableField(prop, this.contentElement);
    }).map( prop => {
      return {
        prop, 
        content: this.contentElement[prop]
      }
    });
  }

  processTextDiff(d, displayEditField) {

    const prop = this.getTextPropFromDiff(d);

    // Get text diffs annotated with indices, authors and dates - any added/removed are not combined into replcaments yet
    let textDiffs = this.itemComponentEditService.getRawTextDiffs(d, this.contentElement.entryId, prop)

    // Filter out diffs that are removing text and being replaced with text
    // leaving only displayed diffs
    const textDisplayedDiffs = [];
    textDiffs.forEach((diff, index) => {
      const isNotEdgeCase = index !== textDiffs.length - 1
      if (isNotEdgeCase) {
        const isAddedRemovedPair = textDiffs[index + 1].added && diff.removed
        const isBySameAuthor = textDiffs[index + 1].author == diff.author
        if (isAddedRemovedPair && isBySameAuthor) {
          this.textDiffService.mapTextDiff(textDiffs[index + 1], diff);
          return
        }
      }
      if (diff.value === " ") {
        diff.value = "\u0020";
      }       
      textDisplayedDiffs.push(diff);
    });
    if(!displayEditField) {
      displayEditField = this.displayEditFields.filter((f) => f.prop === prop)[0];
    }
    displayEditField.textDiff = textDisplayedDiffs;
    displayEditField.path = d.path;
  }

  getTextPropFromDiff(d) {
    if(Array.isArray(d.path)) {
      return d.path[d.path.length - 1];
    } else {
      return d.path;
    }
  }

  genAuthorTextFromDiff(d) {
    return `by ${d.author}, ${d.dateEdited}`
  }
  // Get Fields to Display

  // If it is a caption/content field then it will use diffWords to generate textDiff (with indices for each change)
  // Then the resulting textDiffs are sent to a textDiff component through a variable

  // For any other field, if it is an edit then it will be put in fieldsChanged with the value it changed to and the timestamp
  // if it is an addition or deletion, I will add all displayable fields to the fieldsChanged array
  fieldChange() {
    this.editType = EditType.NONE;
    if(this.itemComponentEditService.usingEditingMode()) {
      this.displayEditFields = this.getAllTextFields();

      const diffs = this.itemComponentEditService.getDiffs(this.contentElement.entryId);
      for(const field of this.displayEditFields) {
        let relevantDiffs = diffs.filter( d => d.path[d.path.length-1] === field.prop);
        if(!relevantDiffs?.length) {
          //Include a dummy deep-diff object for each field we want to include that has no difference
          relevantDiffs = [{
            kind: 'E',
            path: [field.prop], //path doesn't need to be accurate since it is just used for accepting/rejecting which we do not do in editing mode
            lhs: field.content,
            rhs: field.content
          }];
        } else {
          field.timestamp = this.genAuthorTextFromDiff(relevantDiffs[0]);
          this.editType = EditType.EDITED;
        }
        this.processTextDiff(relevantDiffs[0], field);
      }

      return;
    }

    if(this.parentEditType === EditType.ADDED || this.parentEditType === EditType.DELETED) {
      this.displayEditFields = this.getAllViewableFields();
      return;
    }
    this.displayEditFields = []
    
    for (let d of this.itemComponentEditService.getDiffs(this.contentElement.entryId)) {
      // Get the latest value from the edit
      let previewChange = d.rhs || d.rhs === false ? d.rhs.toString() : "";

      let displayEditField;
      
      const authorText = this.genAuthorTextFromDiff(d);
      // Add the displayable field edits to the array
      if (d.kind === "A") {
        // Add all the fields in the new element and filtering out properties that are not needed
        this.displayEditFields = [...this.displayEditFields, ...this.getAllViewableFields()];
        if (d.kind === "A" && d.item.kind === "N") {
          this.editType = EditType.ADDED;
        } else if (d.kind === "A" && d.item.kind === "D") {
          this.editType = EditType.DELETED;
        }
        this.timestamp = authorText;
      } else if (d.kind !== "A") {
        if (this.isViewableField(d.path[d.path.length-1], this.contentElement)) {
          displayEditField = {
            prop: d.path[d.path.length-1], 
            content: previewChange,
            timestamp: authorText};
          this.displayEditFields.push(displayEditField);
            this.editType = EditType.EDITED; //Only set edit type to edited if it was viewable
        }
      }

      if (this.isTextDiffProp(d.path[d.path.length-1])) { //needs to come after modification of displayeditfields
        this.processTextDiff(d, displayEditField);
      }
    }
  }

  // Util

  // Determines if the field is viewable on the frontend
  isViewableField(field, element:IContentElement) {
    return this.itemComponentEditService.isViewableField(field, element);
  }

  isViewableElement(element: IContentElement) {
    return this.itemComponentEditService.isViewableElement(element);
  }

  getElementTypeDisplay() {
    const hiddenElementTypes = [ElementType.TEXT, ElementType.TABLE_TEXT, ElementType.TEXT_PARAGRAPH];
    if(this.itemComponentEditService.usingEditingMode() && hiddenElementTypes.includes(<any>this.contentElement.elementType)) {
      return "";
    }

    return this.contentElement.elementType ? this.contentElement.elementType.toUpperCase() : this.contentElement.fileType == "audio/mp3" ? DEFAULT_VOICEOVER_PROP : "BLANK"
  }

  usingEditingMode() {
    return this.itemComponentEditService.usingEditingMode();
  }

  showTextDiffForProp(prop) {
    return this.isTextDiffProp(prop) && (this.editType !== EditType.ADDED && this.editType !== EditType.DELETED)
  }

  isShowImageField(prop) {
    return this.itemComponentEditService.isImageProp(prop, this.contentElement);
  }

  showInAddOrDelete(){
    const showInAddOrDelete = (this.isShowInAddOrDelete || this.displayEditFieldsToShow().length || this.isNoKeyFieldsOverrideElement() || !this.parentEditType || this.editType !== this.parentEditType)
    if (showInAddOrDelete) this.handleShowInAddOrDelete()
    return showInAddOrDelete
  }

  handleHasEditOrReviewContent(){
    this.hasEditOrReviewContentSignal.emit()
    this.hasEditOrReviewContent = true
  }

  handleShowInAddOrDelete(){
    this.showInAddOrDeleteSignal.emit()
    this.isShowInAddOrDelete = true
  }

  suppressFieldDisplay(field){
    return (!this.isKeyFieldToShowProp(field.prop) || [undefined, null, ""].includes(field.content))
  }

  displayEditFieldsToShow(){
    return [EditType.ADDED, EditType.DELETED].includes(this.editType) ? this.displayEditFields.filter(field => !this.suppressFieldDisplay(field)) : this.displayEditFields
  }
  clearSuggestion(prop) {
    const entryId = this.contentElement.entryId;
    const originalElement = this.itemComponentEditService.deepFind(this.itemComponentEditService.originalQuestionState, 'entryId', entryId); //Getting original item
    this.contentElement[prop] = originalElement[prop];//changing the text value of the item prop has the element thats being changed
    this.refreshChanges();
    this.deselect();
  }

  // If clicking anywhere outside the diffs, disappear the popover
  @ViewChild('popOver') popOverRef!: ElementRef;
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.editStatus && !this.popOverRef?.nativeElement.contains(event.target)) {
      this.editStatus = false;
      this.deselect();
      this.fieldSelected = "";
    }
  }
}
