import { IContentElementText } from "../element-render-text/model";
import { ElementType } from "../models";
import { IElementTypeEditInfo } from './../models/index'

export interface IContentElementAnnotatedText extends IContentElementText {
    text: IContentElementText;
    annotation?: string;
    isLeftAligned?: boolean;
    isConstrainedWidth?: boolean;
    annoteLineHeight?: number; 
}

export const annotationEditInfo: IElementTypeEditInfo = {
    nonHiddenChangeLogFields: ['text', 'advancedList'],
    editExcludeFields: ['text', 'advancedList'],
    
    superType: ElementType.TEXT
  }