import { IContentElement, IScoredResponse } from "src/app/ui-testrunner/models";
import { IContentElementDynamicImage, IContentElementImage } from "../element-render-image/model";
import { IContentElementText } from "../element-render-text/model";
import { SafeHtml } from '@angular/platform-browser';
import { IElementTypeEditInfo } from './../models/index'

export const passageEditInfo: IElementTypeEditInfo = {
  editExcludeFields: [],
  editTextFields: ['text'],
  editKeyFieldsToShow: ['text'],
  nonHiddenChangeLogFields: ['text', 'headerImage', 'header', 'footer']
}
 

export const PASSAGE_LIN_COUNT_INTERVAL_DEFAULT_SYS = 5;

export enum PassageCounterType { 
    LINE = 'LINE',
    PARAGRAPH = 'PARAGRAPH',
    NONE = 'NONE',
}

export type ImageData = {
    image:IContentElementDynamicImage, 
    padding: number, 
    alignment?: 'left' | 'right' | 'center' | 'none'
}

export interface IContentElementPassage extends IContentElement {
    text: string,
    images?: {
        id:number,
        el:IContentElementDynamicImage,
        alignment?:'left' | 'right' | 'center' | 'none',
        padding: number
    }[],
    paragraphStyles?: {
        id:number, 
        tabs: number
    }[],
    counterType: PassageCounterType,
    counterAlignment?: 'left' | 'right',
    lineCountInterval: number,
    isLineCountSkipBlank?: boolean,
    header?: IContentElementText,
    footer?: IContentElementText,
    border?:{
        width: number;
        color: string;
        padding: number;
        isWidthAdjustedForPadding: boolean;
    },
    headerImage?: IContentElementImage
}

export type ISegment = {
  // lines:ILine[], 
  str:string,
  isComplete?:boolean
  lineCount?: number
  isSkipLineCount?: boolean
}

export type IDisplaySegment = {
  contentList?: ILineContent[], 
  isComplete?:boolean, 
  lineCount?:number, 
  isSkipLineCount?: boolean
  inLine?: boolean
}

export type ILineContent = {
  content: SafeHtml | {image: IContentElementDynamicImage, styling: {} }, 
  isText: boolean
}
