import { IContentElement } from "../models";
import { IElementTypeEditInfo } from './../models/index'

export interface IContentElementTable extends IContentElement {
    grid: Array<Array<IContentElementTableCell>>;
    isHeaderRow: boolean;
    isHeaderCol: boolean;
    isColWidthConst: boolean;
    isTableOfValues?: boolean;
    colWidthConst: number;
  }
  export interface IContentElementTableCell extends IContentElement{
    val: string;
    align?: string;
  }

  export const tableTextEditInfo: IElementTypeEditInfo = {
    editExcludeFields: [],
    editTextFields: ['val'],
    editKeyFieldsToShow: ['val'],
    nonHiddenChangeLogFields: ['val'],
    isNoKeyFieldsOverride: true
  }


  export const tableEditInfo: IElementTypeEditInfo = {
    editExcludeFields: ['grid'],
    nonHiddenChangeLogFields: [],
    editKeyFieldsToShow: [null]
  }