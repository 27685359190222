<form>
  <mat-form-field class="full-width" appearance="fill">
    <mat-label><tra [slug]="caption"></tra></mat-label>
    <mat-chip-list #chipList>
      <mat-chip class="mat-chip-multiline"
          *ngFor="let item of selectedItems"
          [selectable]="true"
          [removable]="true"
          (removed)="removeItem(item)">
          {{renderItemChip(item)}}
          <i (click)="removeItem(item)" style="cursor:pointer;margin-left:10px;color:grey" class="fas fa-times-circle"></i>
      </mat-chip>
      <input [placeholder]="lang.tra(placeholder)" #itemInput [formControl]="itemCtrl"
        [matChipInputFor]="chipList"
        [matAutocomplete]="auto"
        [disabled]="isDisabled"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="clearInput($event)"/>
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectItem($event)">
      <mat-option class="mat-option-multiline" *ngFor="let item of filteredItems | async" [value]="item">
        <div [innerHTML]="renderItemOption(item)"></div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>