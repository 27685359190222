<div
    class="bounding-box"
    [style.width.em]="getEmWidth()"
    [style.height.em]="getEmHeight()"
    [style.border]="isShowBoundingBox() ? '1px dashed #000' : '1px solid transparent'"
    style="vertical-align: center;"
>
  <div *ngIf="element._isUploadingImage && !element.diagram" class="upload-info-text">
    <i class="fas fa-exclamation-circle"></i>
    <span>Uploading Image...</span>
  </div>
  <render-image 
    *ngIf="element.diagram" 
    [element]="element.diagram"
    [style.opacity]="element._isEditingBoundingBox ? '0.3' : '1'"
  ></render-image>
</div>
