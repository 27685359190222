// Some functions used for rendering passages stored here so that they can be reused for highlight note functionality outside of the rendered passage

import { IContentElementPassage, ImageData, ISegment, IDisplaySegment, ILineContent } from './model';
import { DomSanitizer } from '@angular/platform-browser';
import { StyleprofileService } from './../../core/styleprofile.service';

/**
 * Process the passage config into segments to be rendered
 */
export const getPassageSegments = (element: IContentElementPassage, lastTags: string[], sanitizer:DomSanitizer, styleProfile: StyleprofileService) => {
  let htmlContent = element.text || '';
  // use custom small caps
  htmlContent = htmlContent.replace(/<sc\b[^>]*>(.*?)<\/sc>/g, '<span class="small-caps">$1</span>');
  // apply bookmark tags (and other style profile transforms)
  htmlContent = processPStyle(htmlContent, element);
  htmlContent = processBookmark(htmlContent, lastTags);
  htmlContent = styleProfile.processBookmark(htmlContent);
  htmlContent = styleProfile.processBoldedMarkup(htmlContent);
  htmlContent = styleProfile.processItalicsMarkup(htmlContent);
  htmlContent = styleProfile.processTooltip(htmlContent);
  
  const segments:ISegment[] = [];
  let chunks:string[] = [];
  const resetChunks = () => chunks = [];
  resetChunks();

  const htmlContentSplit = htmlContent.split('\n')
  let isSegmentCountSkip = false

  if (element.counterType === 'LINE'){
    let lineCount = 0
    for (let line of htmlContentSplit){
      // strippedLine holds the version of the line without bookmark tags to check for emptyness
      let strippedLine = line.replace(/<div class="bookmark id-[^>]+>/g, '');
      strippedLine = strippedLine.replace(/<\/div>/g, '');
      // bookmarkTag holds the original bookmark tag before it was replaced.
      const bookmarkTag = line.match(/<div class="bookmark id-[^>]+>/g)

      const isLineFilled = ((strippedLine || ' ').trim() != '');
      isSegmentCountSkip = !(isLineFilled || !element.isLineCountSkipBlank);
      line = line.replace(/<skip\/>/g, (match:string, id:string) => {
        isSegmentCountSkip = true
        return '';
      })

      // If the line is empty, an empty space needs to be added to be parsed by the HTML
      if(!isLineFilled && bookmarkTag.length > 0) {
        chunks.push(`${bookmarkTag[0]} </div>`)
      } else {
        chunks.push(line)
      }
      
      lineCount = getLineCount(element, isSegmentCountSkip, undefined, lineCount)

      if (lineCount % element.lineCountInterval == 0){
        segments.push({ 
          str: chunks.join('\n'), 
          isComplete:true, 
          isSkipLineCount: isSegmentCountSkip,
          lineCount: lineCount
        })
        resetChunks();
      }
    }
  }
  else if (element.counterType === 'PARAGRAPH'){
    for (let line of htmlContentSplit){
      let strippedLine = line.replace(/<div class="bookmark id-[^>]+>/g, '');
      strippedLine = strippedLine.replace(/<\/div>/g, '');
      
      line = line.replace(/<skip\/>/g, (match:string, id:string) => {
        isSegmentCountSkip = true
        return '';
      })

      const isBlankLine = strippedLine.trim() == ''
      
      if (!isBlankLine){
        chunks.push(line);
      }
      if (isBlankLine && chunks.length > 0){
          segments.push({
            str: chunks.join('\n'), 
            isComplete: true, 
            lineCount: getLineCount(element, isSegmentCountSkip, segments),
            isSkipLineCount: isSegmentCountSkip
          });
          resetChunks();
          isSegmentCountSkip = false
      }
    }
  }
  else if (element.counterType === 'NONE'){
    segments.push( {str:htmlContentSplit.join('\n')} )
  }
  // push whatever is left
  if (chunks.length){
    segments.push( {
      str: chunks.join('\n'),
      isComplete: (element.counterType === 'PARAGRAPH'), // if paragraph mode, then whereever the text ends is considered the end of the paragraph (dont need another set of spaces afterwards)
      isSkipLineCount: isSegmentCountSkip,
      lineCount: getLineCount(element, isSegmentCountSkip, segments)
    })
  }

  const newSegments = processImages(segments, element, sanitizer)
  return newSegments;
}

/**
 * 
 * @param htmlContent html content as 1 single string
 * @param element the input element
 * @returns returns a list of lines in the format of a list containing html or images.
 * @description splits up each line of of text when there is an image into separate segments and replaces image tag with image data to be used for populating an image block
 */
const processImages = (segments: ISegment[], element: IContentElementPassage, sanitizer:DomSanitizer) => {
  const bookmarkRegex = /<div class="bookmark id-[^>]+>/g
  const closingBookmarkTag = /<\/div>/g;

  const imageRef = getImageRefs(element)
  let openTags = []

  const result:IDisplaySegment[] = [];
  
  segments.forEach(segment => {
    const line = segment.str
    let remainingLine = line;
    const splits: ILineContent[] = [];
    let inLine = true
    while (true) {
      const match = /<img id="(\d+)">/.exec(remainingLine);

      if (match && imageRef.get(+match[1])) {
        const parts = remainingLine.split(match[0]);
        const image = imageRef.get(+match[1]) //get the image based off image id
        const imageStyle = getImageStyling(image)

        if(image.alignment !== 'none') inLine = false

        let stringToAdd = parts[0]
        if(openTags.length> 0){
          stringToAdd =openTags.join("") + stringToAdd 
        }

        const openMatches = parts[0].match(bookmarkRegex)
        if (openMatches) openTags.push(...openMatches)
        const closingTags = parts[0].match(closingBookmarkTag)
        if (closingTags) {
          // Needed to close the line div
          closingTags.push('</div>')
        }
    
        // remove the number of tags that are being closed
        for(const _tag in closingTags){
          if(openTags.length > 0){
            openTags.pop()
          }
        }
        // close any open tags
        stringToAdd = stringToAdd + "</div>".repeat(openTags.length)
        
        splits.push({content: sanitizer.bypassSecurityTrustHtml(stringToAdd), isText: true}, {content: {image: image.image, styling: imageStyle}, isText: false});
        remainingLine = parts[1];
      } else {
        const stringToAdd = openTags.join("") + remainingLine
        openTags = []

        splits.push({content: sanitizer.bypassSecurityTrustHtml(stringToAdd), isText: true} );
        break;
      }
    }

    result.push({
      contentList: splits,  
      isComplete: segment.isComplete,
      lineCount: segment.lineCount,
      isSkipLineCount: segment.isSkipLineCount,
      inLine: inLine
    });
  });

  return result;
}


/**
 * 
 * @param element the input element
 * @returns 
 */
const getImageRefs = (element: IContentElementPassage): Map<number, ImageData> => {
  return new Map(
    element.images?.map((image) => [
      +image.id,
      {
        image: image.el,
        padding: image.padding,
        alignment: image.alignment,
      } as ImageData,
    ])
  );
}


const processPStyle = (htmlContent: string, element:IContentElementPassage): string => {
  const htmlContentSplit = htmlContent.split('\n')

  return htmlContentSplit.map(line => {
    let tabs:number;
    line = line.replace(/<pstyle id="(\d+)"\/?>/g, (match:string, id:string) => {
      for (let ps of element.paragraphStyles){
        if (+ps.id == +id){
          tabs = ps.tabs;
        }
      }
      return '';
    });
    if (tabs){
      line = line.replace('<t/>', () =>{
        return `<span style="display:inline-block;width:${tabs}em"></span>`
      })
    }
    return line;
  }).join('\n');
}


const processBookmark = (html: string, lastTags: string[]): string => {
  const passage = html.split('\n')
  let processedPassage = passage.map((line, i) => {
    return `<bookmark id="line_${i+1}">${openBookmarkTags(closeBookmarkTags(line, lastTags), lastTags)}</bookmark>`;
  });
  return processedPassage.join('\n');
}

const openBookmarkTags = (html: string, lastTags: string[]): string => { //need to account for closing tags
  const closedTags = /<\/bookmark>/gs;
  const openTags = /<bookmark(.*?)>/gs;
  const htmlClosedTags = html.match(closedTags);
  const htmlOpenTags = html.match(openTags);
  if (htmlOpenTags && !htmlClosedTags) {
      return html;
  } else if(!htmlClosedTags && !htmlOpenTags && lastTags.length > 0) {
      const openTags = lastTags.join('');
      const closeTags = Array.from({ length: lastTags.length }, (_, index) => `</bookmark>`).join('');
      return openTags + html + closeTags;
  } else if(htmlClosedTags) {
      const openArray = htmlOpenTags ? Array.from(htmlOpenTags) : [];
      const closedArray = Array.from(htmlClosedTags);
      const closeTagCount = closedArray.length - openArray.length;

      if(closeTagCount <= 0) {
          return html;
      }

      const openTags = [];
      for(let i=0;i<closeTagCount; i++) {
          openTags.push(lastTags.shift())
      }
      
      return openTags.join('') + html;
  }

  return html;
}


const closeBookmarkTags = (html: string, lastTags: string[]): string => {
  const closedTags = /<\/bookmark>/gs;
  const openTags = /<bookmark(.*?)>/gs;
  const htmlClosedTags = html.match(closedTags);
  const htmlOpenTags = html.match(openTags);
  if ((!htmlClosedTags && !htmlOpenTags) || (htmlClosedTags && !htmlOpenTags)) {
      return html;
  } else if(htmlOpenTags) {
      const closedArray = htmlClosedTags ? Array.from(htmlClosedTags) : [];
      const openArray = Array.from(htmlOpenTags);
      const openTagCount = openArray.length - closedArray.length;
      if(openTagCount <= 0) {
          return html;
      }

      lastTags.push(...openArray);
      const closeTags = Array.from({ length: openTagCount }, (_, index) => `</bookmark>`);
    
      return html + closeTags.join('');
  }

  return html;
}


/**
 * 
 * @param image the image to get styling for
 * @returns the css styles needed to be applied for that image
 */
const getImageStyling = (image: ImageData): {} => {
  let imageStyle = {}

  if(image.padding > 0) {
    if(image.alignment && image.alignment === 'right'){
      imageStyle['padding-left'] = `${image.padding}em`
    }else{
      imageStyle['padding-right'] = `${image.padding}em`
    }
  }

  if(image.alignment) {
    if(image.alignment === 'right'){
      imageStyle['float'] = 'right'
    }else if(image.alignment === 'left'){
      imageStyle['float'] = 'left'
    } else if(image.alignment === 'center'){
      imageStyle['justify-content'] = 'center'
      imageStyle['display'] = 'flex'
    }
  }

  return imageStyle
}


/**
 * 
 * @param element - input element
 * @param isSegmentCountSkip boolean value indicating if we want to skip counting this line/paragraph
 * @param segments Used for paragraph counter type
 * @param lineCount Used for line counter type
 * @returns lineCount to be applied to line/paragraph
 * @description determines the line count that needs to be applied for 
 */
const getLineCount = (element: IContentElementPassage, isSegmentCountSkip:boolean, segments?:ISegment[], lineCount?:number) : number => {
  if (element.counterType == 'PARAGRAPH'){
    if(isSegmentCountSkip){
      if(segments.length<1) return 0
      
      return segments[segments.length-1].lineCount
    }
    if(segments.length<1) return 1
    return segments[segments.length-1].lineCount + 1
  }
  else if (element.counterType == 'LINE'){
    if (!isSegmentCountSkip){
      lineCount += 1
    }
    return lineCount
  }
}

/**
 * @param segments - Latest segments of the passage
 * @returns `combinedTextOnlyHtml` - Merged string of the rendered HTML of the text only parts of the passage against which the range of a highlight note is specified, `contentIntervals` - 2D array specifying where each segment-content used in the rendered passage starts and ends in the combinedTextOnlyHtml string
 */
export const processTextForHighlighter = (segments): {combinedTextOnlyHtml: string, contentIntervals: {start:number, end:number}[][]} => {
  let start = 0;
  let end = 0;
  let combinedTextOnlyHtml = '';
  const contentIntervals = []
  segments.forEach(segment => {
    const segmentContentIntervals = []
    segment.contentList.forEach(content => {
      if (!content.isText) {
        segmentContentIntervals.push({})
        return
      };
      //@ts-ignore
      const textHtmlString = content.content.changingThisBreaksApplicationSecurity;
      combinedTextOnlyHtml += textHtmlString
      end += textHtmlString.length
      segmentContentIntervals.push({start, end})
      start = end
    })
    contentIntervals.push(segmentContentIntervals)
  })
  return { combinedTextOnlyHtml, contentIntervals };
}