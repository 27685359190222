import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { IContentElementPassage, ImageData, ISegment, IDisplaySegment, ILineContent } from './model';
import { QuestionState } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { StyleprofileService } from 'src/app/core/styleprofile.service';
import { AuthScopeSettingsService } from 'src/app/ui-item-maker/auth-scope-settings.service';
import { LangService } from 'src/app/core/lang.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { TextToSpeechService } from '../text-to-speech.service';
import { EditSelectionService } from '../edit-selection.service';
import { IContentElementDynamicImage } from '../element-render-image/model';
import { TEST_RUNNER_WIDTH_EM } from '../element-render/element-render.component';
import { getWidth } from 'src/app/ui-item-maker/element-config-passage/element-config-passage.component';
import { HighlighterService } from '../highlighter.service';
import { getPassageSegments, processTextForHighlighter} from "./functions"

const PARARGRAPH_SPACING = 1;
type ILine = {
  // identSpans:{
  //   str: string,
  //   width: number,
  // }[]
}
@Component({
  selector: 'element-render-passage',
  templateUrl: './element-render-passage.component.html',
  styleUrls: ['./element-render-passage.component.scss']
})
export class ElementRenderPassageComponent implements OnInit, AfterViewInit {

  @Input() element:IContentElementPassage;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter:number;
  @Input() questionPubSub?: QuestionPubSub;

  images: {
    id: string, 
    imageData: ImageData
  }[] = [];
  lastTextSnapshot:string;

  segments:IDisplaySegment[];
  contentIntervals: {start:number, end:number}[][];
  lastTags: string[] = [];

  constructor(
    private lang:LangService,
    private styleProfile:StyleprofileService,
    private authScope: AuthScopeSettingsService,
    private sanitizer: DomSanitizer,
    public textToSpeech: TextToSpeechService,
    public editSelection: EditSelectionService,
    private highlighter: HighlighterService
  ) { }

  ngOnInit() {
    // this.updateRender();
    // this.profile.getStyleProfileChanges().subscribe((hasStyleProfile) => {
    //   if(hasStyleProfile) {
    //     this.updateRender();
    //   }
    // })
  }

  ngAfterViewInit(): void {
    this.images.forEach((img) => {
      this.addImgListener(img.id, img.imageData);
    })
  }

  ngOnDestroy(): void {
    this.handleAuthHighlighterOnDestroy();
    // if(this.styleProfileChangeSub) {
    //   this.styleProfileChangeSub.unsubscribe();
    // }
  }

  voiceover = null;
  getCurrentVoiceoverUrl() {
    return this.voiceover?.url || null;
  }

  clickTrigger = new Subject<boolean>();
  addImgListener(id: string, imageData: any){
    document.getElementById(id).addEventListener('click', () => {
      this.voiceover = imageData.voiceover
      this.clickTrigger.next(true);
    })
  }

  getheaderEnabled(){
    return this.element.isShowAdvancedOptions && this.element.header && this.element.header.caption.trim() != ""
  }

  getfooterEnabled(){
    return this.element.isShowAdvancedOptions && this.element.footer && this.element.footer.caption.trim() != ""
  }
  /**
   * 
   * @returns the passage styling based on element config
   */
  getPassageStyling(){
    let styles = {}
    if(this.element.border){
      const {width, color, padding} = this.element.border
      
      if(padding > 0){
        styles['padding'] = `${padding}em`
        if(this.isCounterAlignRight()){
          styles['padding-right'] = `${2+padding}em`
        }else{
          styles['padding-left'] = `${2+padding}em`
        }
      }
    }
  
    return styles
  }

  getBorderStyling() {
    let styles = {}
    if(this.element.border){
      const {width, color, padding} = this.element.border
      styles['border'] = `solid ${width}px ${color}`
    }
  
    return styles
  }
  
  getWidth() {
    return getWidth(this.element);
  }

  renderTextSegments(){
    const getBookmarkTag = (i, body: string, isParagraph?: boolean): string => {
      return `<div class="bookmark id-${isParagraph ? 'paragraph' : 'line'}_${i+1}">${body}</div>`
    }
    const snapshot = this.element._changeCounter+this.element.text
    if (this.lastTextSnapshot != snapshot){
      this.lastTextSnapshot = snapshot

      this.segments = getPassageSegments(this.element, this.lastTags, this.sanitizer, this.styleProfile)

      // Account for changes in the passage in the highlighter
      const { combinedTextOnlyHtml, contentIntervals } = processTextForHighlighter(this.segments)
      this.contentIntervals = contentIntervals;
      this.highlighter.processPasageUpdate(this.element.entryId, combinedTextOnlyHtml)
    }
    return this.segments
  }

  handleAuthHighlighterOnDestroy(){
    this.highlighter.invalidateElementHighlights(this.element.entryId, 'text');
    this.highlighter.clearCachedPassageHtml(this.element.entryId)
  }

  paragraphSpacing(){
    if (this.element.counterType == 'PARAGRAPH'){
      return PARARGRAPH_SPACING;
    }
    return 0
  }

  // default is right align
  isCounterAlignRight(){
    return !this.isCounterAlignLeft()
  }
  isCounterAlignLeft(){
    return (this.element.counterAlignment === 'left')
  }

  isLinesMode(){
    return (this.element.counterType == 'LINE')
  }
  isParagraphMode(){
    return (this.element.counterType == 'PARAGRAPH')
  }

  isShowCounter(){
    return (this.element.counterType !== 'NONE') && (+this.element.lineCountInterval > 0);
  }

}
