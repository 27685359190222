<ng-container [ngSwitch]="!!element.isParagraphMode"> 
    <ng-container *ngSwitchCase="false">
        <div [ngStyle]="getStyle()">
            <div class="block-el" *ngFor="let block of blocks; let i = index;" (click)="!isLocked ? toggleAnswer(block.index) : ''" [ngStyle]="getWordStyle(block, i)">
              <render-highlightable
              [inputString]="element.texts[block.index].content"
              [entryId]="element.texts[block.index].entryId"
              [prop]="'content'"
              [isWholeHighlight]="true"
              (click)="highlighter.checkInitWholeHighlight(element.texts[block.index].entryId, 'content', element.texts[block.index].content)"
              >
                <markdown-inline [input]="getFormattedWord(block.word)"></markdown-inline>
              </render-highlightable>      
            </div>
        </div>        
    </ng-container>
    <ng-container *ngSwitchCase="true">
        <ng-container *ngFor="let paragraph of paragraphs; let idx = index">
            <div [ngStyle]="getStyle(idx)">
                <div class="block-el" *ngFor="let block of paragraph; let i = index;" (click)="!isLocked ? toggleAnswer(block.index) : ''" [ngStyle]="getWordStyle(block, block.wordIdx)">
                  <render-highlightable
                  [inputString]="element.texts[block.index].content"
                  [entryId]="element.texts[block.index].entryId"
                  [prop]="'content'"
                  [isWholeHighlight]="true"
                  (click)="highlighter.checkInitWholeHighlight(element.texts[block.index].entryId, 'content', element.texts[block.index].content)"
                  >
                    <markdown-inline [input]="getFormattedWord(block.word)"></markdown-inline>
                  </render-highlightable>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
<div *ngIf="this.showMaxSelectedMsg" (click)="!isLocked ? this.showMaxSelectedMsg = false : ''" class="mcq-max-selected-msg">
    {{getMaxMsgText()}}
    <div class="click-dismiss-msg">
        {{getClickToDismissMsg()}}
    </div>
</div>