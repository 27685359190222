<div>
    <a (click)="openLink()" (keydown.enter)="openLink()" [style.color]="isHighContrast ? '#ff0' : ''" tabindex="0" style="display: flex; flex-direction: row; justify-content: flex-start" > 
      <i *ngIf="!element.isIconDisabled" class="fas fa-link"></i>&nbsp;
      <!-- use tra-md for &nbsp; and other html rendering if needed -->
      <render-highlightable
        [inputString]="element.caption"
        [entryId]="element.entryId"
        [prop]="'caption'"
      >
        <tra-md [isCondensed]="true" [style.position]="element.baseLine ? 'relative' : ''" [style.top.em]="element.baseLine" 
        style="font-weight: normal; text-decoration:underline" [slug]="element.caption"></tra-md>
      </render-highlightable>
      <render-image *ngIf="element.thumbNail" [element]="element.thumbNail"></render-image>
    </a>
</div>
