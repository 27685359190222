<div [ngStyle]="getBorderStyling()" [style.width]="'fit-content'">
    <element-render-image *ngIf="element.headerImage" [element]="element.headerImage"></element-render-image>
    <div 
        class="passage-container"
        [class.numbers-align-left]="isCounterAlignLeft()"
        [class.numbers-align-right]="isCounterAlignRight()"
        [class.is-selected-edit]="editSelection.isSelectedEdit(element, questionPubSub)"
        [style.border]="editSelection.getBorder(element, questionPubSub)"
        [ngStyle]="getPassageStyling()"
    >
        <div 
            class="passage" 
            [style.width.em]="getWidth()"
        >
            <div *ngIf="getheaderEnabled()">
                <element-render-text [element]="element.header"></element-render-text>
            </div>
    
            <!-- todo:OPTIMIZE we should only re-render the text segments when there is a change to the text -->
            <div 
                *ngFor="let textSegment of renderTextSegments(); let i = index" 
                style="position:relative"
                [style.margin-bottom.em]="paragraphSpacing()"
            >
                  <div [class.inline-blocks]="textSegment.inLine" [ngStyle]="content.content.styling" *ngFor="let content of textSegment.contentList; let j = index">
                      <element-render-image *ngIf="!content.isText" [element]="content.content.image"></element-render-image>
                      <render-highlightable
                      *ngIf="content.isText" 
                      [inputHtml]="content.content.changingThisBreaksApplicationSecurity"
                      [entryId]="element.entryId"
                      [prop]="'text'"
                      [isDiv]="true"
                      [isPassage]="true"
                      [passageContentInterval]="contentIntervals[i][j]"
                      >
                        <div [innerHTML]="content.content"></div>
                      </render-highlightable>
                  </div>
            
                <div 
                    *ngIf="isShowCounter() && textSegment.isComplete" 
                    class="passage-numbering-placer"
                    [class.is-lines]="isLinesMode()"
                    [class.is-paragraph]="isParagraphMode()"
                    [class.align-left]="isCounterAlignLeft()"
                    [class.align-right]="isCounterAlignRight()"
                >
                    <span *ngIf="!textSegment.isSkipLineCount" class="passage-numbering">{{textSegment.lineCount}}</span>
                </div>
            </div>
    
            <div *ngIf="getfooterEnabled()">
                <element-render-text [element]="element.footer"></element-render-text>
            </div>
        </div>   
        <render-audio
            *ngIf="getCurrentVoiceoverUrl()"
            [url]="getCurrentVoiceoverUrl()"
            [trigger]="clickTrigger"
            [isTriggerDisabled]="!textToSpeech.isActive"
        ></render-audio> 
    </div>    
</div>