import { IContentElement } from "../models";
import { IElementTypeEditInfo } from './../models/index'

export interface IContentElementDocLink extends IContentElement {
  url?: string;
  fileType?: string;
  caption?: string;
}

export const docLinkEditInfo: IElementTypeEditInfo = {
  editExcludeFields: [],
  nonHiddenChangeLogFields: ['caption'],
  editTextFields: ['caption']
}