import { Injectable } from '@angular/core';
import { DB_Roles_TA } from '../ui-testadmin/my-institution.service';
import * as _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthService } from '../api/auth.service';
import { UserRoles } from '../api/models/roles';
import { RoutesService } from '../api/routes.service';
import { LangService } from '../core/lang.service';
import { WhitelabelService } from '../domain/whitelabel.service';
import { AuthRolesService } from './auth-roles.service';
import {AuthScopeSetting, AuthScopeSettingsService} from "./auth-scope-settings.service";
import { IItemTag } from './item-tag/item-tag.component';

export const PERSONAL_GROUP_DESC = '%PERSONAL%';

export interface IItemSetDef {
  id?: number,
  group_id: number,
  single_group_id?: number,
  slug: string,
  name: string,
  description: string,
  num_items?: number,
  is_test_design?: number,
}

export interface IAuthGroupDef {
    description: string,
}

export interface IAuthoringGroupEntry{
  id?: number,
  group_id?: number,
  uid?: number,
  description: string,
  role_type: string,
  is_single?: boolean
}

export interface IAuthoringGroup {
  group_id: number,
  description: string,
  isSuper?:boolean,
  isPersonal?:boolean,
  isSingle?: boolean,
  roles: UserRoles[]
}

@Injectable({
  providedIn: 'root'
})
export class ItemMakerService {

  public myItemSets:IItemSetDef[];
  public myFrameworks:IItemSetDef[];
  public myGroups:IAuthoringGroup[];
  public isRolesLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public groupsAsSuper:IAuthoringGroup[];
  public authGroupsAsSuper:IAuthoringGroup[];
  public hasGroupsAsSuper: boolean;
  public availableTags:IItemTag[] = [];
  
  private myGroupsMap:Map<number, IAuthoringGroup>;

  constructor(
    private auth:AuthService,
    private routes:RoutesService,
    private lang: LangService,
    private authRoles: AuthRolesService,
    private whitelabel: WhitelabelService,
    public authScopeSettings: AuthScopeSettingsService,
  ) {
  }
  /**
   * Retrieves the user's item sets
   * 
   * @param getArchived - Flag indicating whether to retrieve only the archived items.
   * @returns - An array of item sets accessible to the user.
   */
  loadMyItemSets(getArchived = false){
    return this.auth
      .apiFind(this.routes.TEST_AUTH_ITEM_SET, {query:{getArchived: getArchived ? 1 : 0}})
      .then(res => {
        // res.data.forEach()
        this.myItemSets = _.sortBy(res, ['slug', 'name']);
        const arr = [];
        this.myItemSets.forEach(itemSet => {
          if (!itemSet.is_test_design){
            arr.push(itemSet);
          }
        })
        return arr;
      }).catch(err=>console.error(err));
  }

  loadMyFrameworks(getArchived = false){
    return this.auth
      .apiFind(this.routes.TEST_AUTH_FRAMEWORKS,  {query:{getArchived: getArchived ? 1 : 0}})
      .then(res => {
        this.myFrameworks = _.sortBy(res, ['group_id', 'name']);
        return this.myFrameworks;
      }).catch(err=>console.log(err));
  }

  loadMyGroupMembers(single_group_id, group_id) {
    return this.auth
        .apiFind(this.routes.TEST_AUTH_GROUP_MEMBERS, {
            query: {
                single_group_id,
                group_id,
                auth_group_ids: [single_group_id, group_id]
            }
        })
    }

  private loadAuthGroups(refresh: boolean) {
    if(this.myGroups && !refresh) {
      return Promise.resolve([]);
    }

    const groupRef:Map<number, IAuthoringGroup> = new Map();
    const groups:IAuthoringGroup[] = [];

    return this.auth
      .apiFind(this.routes.TEST_AUTH_GROUPS, {})
      .then(res => {
        
        const superRoles: UserRoles[] = this.authRoles.superRoles;
        const groupsEntries:IAuthoringGroupEntry[] = res.data;
        
        groupsEntries.forEach(entry => {
          const group_id = entry.group_id;
          if (!groupRef.has(group_id)){
            const description = entry.description;
            const group:IAuthoringGroup = {
              group_id,
              description,
              isSingle: entry.is_single,
              roles: [<UserRoles>entry.role_type]
            }
            groups.push(group);
            groupRef.set(group_id, group);
          } else {
            groupRef.get(group_id).roles.push(<UserRoles>entry.role_type);
          }
          const group = groupRef.get(group_id);
          if (entry.description === PERSONAL_GROUP_DESC){
            group.isPersonal = true;
          }
          if ( superRoles.includes(<UserRoles>entry.role_type)){
            group.isSuper = true;
          }
        })
        this.myGroups = _.sortBy(groups, ['description']);
        this.updateRoleFlags();
        this.myGroupsMap = groupRef;
        this.isRolesLoaded.next(true);
        return this.myGroups;
      })
  }
  
  loadMyAuthoringGroups(refresh: boolean = true){
    return this.loadAuthGroups(refresh).then( () => {
      this.updateRoleFlags();
    })
  }

  getMyFrameworks(){
    return this.myFrameworks;
  }

  public updateRoleFlagsFromAuthGroup(authGroupId: number) {
    this.updateRoleFlags(this.myGroupsMap.get(authGroupId)?.roles); //sometimes authGroupId is 24 for the general library, correctly uses undefined then.
  }

  public updateRoleFlags(roles?: UserRoles[]) {
    if(!roles) {
     const rolesArr = this.myGroups.filter(g => !g.isPersonal).map(g => g.roles);
     roles = [].concat(...rolesArr);
    }

    let allReadOnly: boolean = true;

    let hasAllEditingRoles: boolean = true;
    let hasSuperRole: boolean = false; 
    let hasBcSuperRole: boolean = false;
    let hasEqaoReadOnlyRole: boolean = false;

    let enableReview: boolean = false;
    const readOnlyRoles : UserRoles[] =  this.authRoles.readOnlyRoles;
    const editingRoles : UserRoles[] = [UserRoles.TEST_ITEM_AUTHOR_REV];
    const superRoles = this.authRoles.superRoles;
    const bcSuperRoles = this.authRoles.bcSuperRoles;
    for(const role of roles) {
      if(role === UserRoles.EQAO_AUTH_READ_ONLY) {
        hasEqaoReadOnlyRole = true;
      }

      if(!editingRoles.includes(role)) {
        hasAllEditingRoles = false;
      }
      
      if(!readOnlyRoles.includes(role)) {
        allReadOnly = false;
      }
      
      
      //Enable question locking mechanism for super users
      if( superRoles.includes(role)) {
        hasSuperRole = true;
      }

      if (bcSuperRoles.includes(role)) {
        hasBcSuperRole = true;
      }
    }

    this.authScopeSettings.setSetting(AuthScopeSetting.IS_EDITOR, hasAllEditingRoles)
    this.authScopeSettings.setSetting(AuthScopeSetting.USE_EDITING_MODE, hasAllEditingRoles)
    this.authScopeSettings.setSetting(AuthScopeSetting.DISABLE_TRACKED_CHANGES_CTRL, hasAllEditingRoles);
    this.authScopeSettings.setSetting(AuthScopeSetting.ENABLE_SUGGESTIONS, true);
    this.authScopeSettings.setSetting(AuthScopeSetting.DISABLE_ITEM_PARAMS, allReadOnly)
    this.authScopeSettings.setSetting(AuthScopeSetting.DISABLE_GRPAHIC_REQ, (allReadOnly || hasAllEditingRoles))

    this.authScopeSettings.setSetting(AuthScopeSetting.DISABLE_EDITING, allReadOnly);
    if (hasSuperRole) {
      this.authScopeSettings.setSetting(AuthScopeSetting.ENABLE_Q_LOCK, true);
      this.authScopeSettings.setSetting(AuthScopeSetting.ENABLE_COMMENT_DEL, true);
      this.authScopeSettings.setSetting(AuthScopeSetting.ENABLE_COMMENT_SEE_ALL, true)
      this.authScopeSettings.setSetting(AuthScopeSetting.ENABLE_COMMENT_SHARE, true)
      this.authScopeSettings.setSetting(AuthScopeSetting.ENABLE_PSYCHO_PARAM_EDIT, true);
    }
    if (hasBcSuperRole) {
      this.authScopeSettings.setSetting(AuthScopeSetting.ENABLE_COMMENT_EDIT, true);
      this.authScopeSettings.setSetting(AuthScopeSetting.ENABLE_COMMENT_IMPORT, true);
    }
    this.authScopeSettings.setSetting(AuthScopeSetting.DISABLE_REVIEW_EDIT_TAB, hasEqaoReadOnlyRole);

  }
  
  createNewTestDesign(payload:IItemSetDef){
    payload.is_test_design = 1;
    return this.auth
      .apiCreate(this.routes.TEST_AUTH_ITEM_SET, payload)
      .then(newRecord => {
        this.myFrameworks.push(newRecord);
        return newRecord;
      })
  }

  createNewItemBank(payload: IItemSetDef) {
    return this.auth
      .apiCreate(this.routes.TEST_AUTH_ITEM_SET, payload)
      .then(newRecord => {
        this.myItemSets.push(newRecord);
        return newRecord;
      })
  }

  createNewAuthGroup(payload: IAuthGroupDef) {
      return this.auth
          .apiCreate(this.routes.TEST_AUTH_GROUP, payload)
          .then(newRecord => {
              const group = {
                  group_id: newRecord.group_id,
                  description: newRecord.description,
                  isSuper: true,
                  roles: [UserRoles.TEST_ITEM_AUTHOR, UserRoles.TEST_ITEM_AUTHOR_SUPER]
              };
              this.myGroups.push(group);
          });
  }

  archiveItemBank(id: number) {
    return this.auth
      .apiRemove(this.routes.TEST_AUTH_ITEM_SET, id)
      .then(res => {
        let i = -1;
        this.myItemSets.forEach((entry:IItemSetDef, index) => {
          if (entry.id === id){
            i = index;
          }
        });
        if (i!==-1){
          this.myItemSets.splice(i, 1);
        }
      })
  }
  recoverItemBank(id: number) {
    return this.auth
      .apiRemove(this.routes.TEST_AUTH_ITEM_SET, id, { query: {isRecovering: true}})
      .then(res => {
        let i = -1;
        this.myItemSets.forEach((entry:IItemSetDef, index) => {
          if (entry.id === id){
            i = index;
          }
        });
        if (i!==-1){
          this.myItemSets.splice(i, 1);
        }
      })
  }
  archiveTestDesign(id:number){
    return this.auth
      .apiRemove(this.routes.TEST_AUTH_ITEM_SET, id)
      .then(res => {
        let i = -1;
        this.myFrameworks.forEach((entry:IItemSetDef, index) => {
          if (entry.id === id){
            i = index;
          }
        });
        // console.log(i, id, this.myFrameworks.length)
        if (i!==-1){
          this.myFrameworks.splice(i, 1);
        }
      })
  }

  getGroupById(groupId:number){
    return this.myGroupsMap.get(groupId);
  }

  getGroupNameById(groupId: number) {
    const group = this.getGroupById(groupId);
    if (group){
      if (group.isPersonal){
        return this.lang.tra('auth_personal');
      }
      return group.description
    }
    return this.lang.tra('auth_group');
  }

  refreshGroupsAsSuper(){
    this.groupsAsSuper = this.myGroups.filter(  entry => entry.isSuper);
    this.authGroupsAsSuper = this.getAuthoringGroups().filter(  entry => entry.isSuper);
    let hasAnyNonPersonalGroups = false;
    this.groupsAsSuper.forEach(group => {
      if (!group.isPersonal){
        hasAnyNonPersonalGroups = true;
      }
    })
    this.hasGroupsAsSuper = hasAnyNonPersonalGroups;
  }

  getAuthoringGroups() {
    if(!this.myGroups) {
      return this.myGroups
    }
    return this.myGroups.filter(g => !g.isSingle);
  }

  loadAvailableTags() {
    const group_ids = this.getAuthoringGroups().map( g => g.group_id) 
    this.availableTags = [];
    // return this.auth.apiFind(this.routes.TEST_AUTH_ITEM_TAGS, {query: {group_ids}}).then(res => {
    //   this.availableTags = res;
    // })
  }

}
