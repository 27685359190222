import { IContentElementCanvas } from "../element-render-canvas/model";
import { IContentElementImage } from "../element-render-image/model";
import { IContentElementOption, McqDisplay } from "../element-render-mcq/model";
import { IContentElementText } from "../element-render-text/model";
import { ElementType, IContentElement, IEntryStateScored, IScoredResponse } from "../models";
import { IElementTypeEditInfo } from './../models/index'

export interface IContentElementOrder extends IContentElement, IScoredResponse {
    displayStyle: McqDisplay;
    options: Array<IContentElementOrderOption>;
    scrambledOptions?: Array<IContentElementOrderOption>;
    delimeter: string;
    isScrambled: boolean;
    showDefaultText?: boolean;
    delimiter?: any;
    orderMode?: OrderMode;
    delimiterType?: string;
    delimiterImg?: IContentElementImage;
    delimiterIcon?: string;
    separatorType?: string;
    separatorText?: IContentElementText;
    separatorImage?: IContentElementImage;
    separatorCanvas?: IContentElementCanvas;
    targetWidth?: number;
    targetHeight?: number;
    isShowDelimiter?: boolean;
    isShowLabels?: boolean;
    isShowSeparator?: boolean;
    isShowSeparatorInRenderHidden?: boolean;
    isCenterDraggableContent?: boolean;
    isCenterLabelContent?: boolean;
    idCount?: number;
    isTargetOrientationReversed?: boolean;
    isDragWidthSet?: boolean;
    widthOfDrags?: number;
  }

  export interface IContentElementOrderOption extends IContentElementOption {
    [key: string]: any;
    isReadOnly?: boolean; //this is only for target mode.
    key_id?: string;
    label?: string;
    labelImg?: IContentElementImage;
    labelType?: string;
    x?: number;
    y?: number;
    labelx?: number;
    labely?: number;
    initOrder?: number;
  }
  export const orderOptionEditInfo: IElementTypeEditInfo = {
    editTextFields: ['label', 'caption'],
    editKeyFieldsToShow: ['label', 'caption', 'optionType', 'isReadOnly'],
    editExcludeFields: ['voiceover'],
    nonHiddenChangeLogFields: ['label', 'caption', 'optionType', 'isReadOnly'],
    superType: ElementType.OPTION
  }
  
  export interface IEntryStateOrder extends IEntryStateScored {
    answers: IContentElementOrderOption[][];
    options: IContentElementOrderOption[];
  }

  export enum OrderMode {
    TARGET = "target",
    REORDER = "reorder"
  }

  export const orderEditInfo: IElementTypeEditInfo= {
    editExcludeFields: ['options', 'scrambledOptions', 'delimiterImg', 'separatorText', 'separatorImage', 'separatorCanvas'],
    editKeyFieldsToShow: ['displayStyle', 'orderMode', 'isShowLabels'],
    nonHiddenChangeLogFields: ['options', 'scrambledOptions', 'displayStyle', 'orderMode', 'scoreWeight']
  } 
