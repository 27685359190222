import { IContentElement } from "../models";
import { IElementTypeEditInfo } from './../models/index'

export interface IContentElementSolution extends IContentElement {
    content: IContentElement[];
    hideFromTestTakers: boolean;
    isRubric: boolean;
    isHeaderHidden: boolean;
}

export const solutionEditInfo: IElementTypeEditInfo = {
    editExcludeFields: ['content', 'voiceover'],
    nonHiddenChangeLogFields: ['content'],
    editKeyFieldsToShow: [null]
}