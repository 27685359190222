import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, ViewChild, ContentChildren, Input, OnInit, Output, ViewChildren } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { DisplayMode } from '../../ui-testrunner/element-render-audio/model';
//import { FontFamily } from 'src/app/ui-testrunner/element-render-mcq/model';
import { IContentElementText, NumberingPosition, IContentElementTextParagraph, TextParagraphStyle } from '../../ui-testrunner/element-render-text/model';
import { ElementType, IElementTypeDef , FontFamily} from '../../ui-testrunner/models';
import { ElementTypeDefs } from "../../ui-testrunner/models/ElementTypeDefs";
import { WhitelabelService } from '../../domain/whitelabel.service';
import { EditingDisabledService } from '../editing-disabled.service';
import { SpecialKeyboardService } from '../special-keyboard.service';
import { createDefaultElement, elementIconById } from '../item-set-editor/models';
import { bindFormControls } from '../services/data-bind';
import { indexOf } from '../services/util';

@Component({
  selector: 'element-config-text',
  templateUrl: './element-config-text.component.html',
  styleUrls: ['./element-config-text.component.scss']
})
export class ElementConfigTextComponent implements OnInit {

  @Input() element:IContentElementText;
  @Output() simpleTextChange = new EventEmitter<string>();
  @ViewChildren('paragraph') paragraphs
  @ViewChild('textBox') textBox:ElementRef

  caption = new FormControl('');
  paragraphStyle = new FormControl(TextParagraphStyle.REGULAR);
  isShowAdvancedOptions = new FormControl(false);
  simpleList = new FormArray([]);
  NumberingPosition = NumberingPosition;
  elementTypes:IElementTypeDef[] = [
    ElementTypeDefs.TEXT,
    ElementTypeDefs.TEXT_LINK,
    ElementTypeDefs.MATH,
    ElementTypeDefs.IMAGE,
    ElementTypeDefs.TABLE,
    ElementTypeDefs.VIDEO,
    ElementTypeDefs.INPUT,
    ElementTypeDefs.MCQ,
    ElementTypeDefs.UPLOAD,
    ElementTypeDefs.SELECT_TEXT,
    ElementTypeDefs.ANNOTATION,
    ElementTypeDefs.BOOKMARK_LINK
  ];

  paragraphStyles = [
    {id: TextParagraphStyle.HEADLINE, caption:'auth_headline'},
    {id: TextParagraphStyle.HEADLINE_SMALL, caption:'auth_sub_headline'},
    {id: TextParagraphStyle.REGULAR, caption:'auth_regular'},
    {id: TextParagraphStyle.SMALL, caption:'auth_small'},
    {id: TextParagraphStyle.LINK, caption: 'auth_link'},
    {id: TextParagraphStyle.BULLET, caption:'auth_bullet_list'},
    {id: TextParagraphStyle.NUMBERED, caption:'auth_numbered_list'},
    {id: TextParagraphStyle.PARAGRAPHS, caption:'auth_paragraphs'},
    {id: TextParagraphStyle.ADVANCED_INLINE, caption:'auth_advanced_inline'},
    {id: TextParagraphStyle.ANNOTATION, caption:'auth_annotation'},
  ]

  fontStyles = [
    {id: FontFamily.ARIAL, caption: "Arial"},
    {id: FontFamily.BIG_CASLON, caption: "Big Caslon"},
    {id: FontFamily.BODONI_MT, caption: "Bodoni MT"},
    {id: FontFamily.BOOKMAN, caption: "Bookman"},
    {id: FontFamily.BOOK_ANTIQUA, caption: "Book Antiqua"},
    {id: FontFamily.CALIBRI, caption: "Calibri"},
    {id: FontFamily.GILL_SANS, caption: "Gill Sans"},
    {id: FontFamily.HELVETICA, caption: "Helvetica"},
    {id: FontFamily.LUCIDA_SANS, caption: "Lucida Sans"},
    {id: FontFamily.NOTO, caption: "Noto"},
    {id: FontFamily.OSWALD, caption: "Oswald"},
    {id: FontFamily.TIMES_NEW_ROMAN, caption: "Times New Roman"},
    {id: FontFamily.VERDANA, caption: "Verdana"},
  ]

  public alignments = [
    {id:'left', icon:'fa-align-left'},
    {id:'center', icon:'fa-align-center'},
    {id:'right', icon:'fa-align-right'},
    {id:'justify', icon:'fa-align-justify'}
  ]

  constructor(
    private editingDisabled: EditingDisabledService,
    private whitelabel: WhitelabelService,
    private specialKeyboard: SpecialKeyboardService
  ) { }

  ngOnInit() {
    this.ensureConfigRules();
    const formControls = [
      {f: this.isShowAdvancedOptions, p:'isShowAdvancedOptions'},
      {f: this.caption, p:'caption'},
      {f: this.paragraphStyle, p:'paragraphStyle'},
      {f: this.simpleList, p:'simpleList', isFormArray:true},
    ];

    bindFormControls(this.element, formControls);
    this.caption.valueChanges.subscribe( () => {
      this.element.caption = this.caption.value;
      this.simpleTextChange.emit(this.caption.value);
    });
    this.paragraphStyle.valueChanges.subscribe( () => {
      this.element.paragraphStyle = this.paragraphStyle.value;
      this.clearOtherParagraphStyleInput()
    });
  }

  /**
 * Makes any necessary adjustments to `this.element` to follow specifications for the TEXT element type
 * In case e.g. the question was created before some updates to the block creation code
 */
  ensureConfigRules(){

    if (this.element.simpleList && this.element.simpleList.length > 0){
      this.element.advancedList = [];
      this.element.simpleList.forEach(caption => {
        const lineAdv = <IContentElementText> createDefaultElement(ElementTypeDefs.TEXT.id)
        lineAdv.caption = caption;
        this.element.advancedList.push(lineAdv);
      });
      this.element.simpleList = [];
    }
    if (!this.element.paragraphList) this.element.paragraphList = [];
    if (!this.element.link) this.element.link = { elementType: ElementType.TEXT_LINK, caption: "" };
    // if (!this.element.font) this.element.font = FontFamily.TIMES_NEW_ROMAN;
    if (!this.element.alignment) this.element.alignment = "left";
    if (!this.element.rotation) this.element.rotation = 0;
    // if (!this.element.font) this.element.font = FontFamily.TIMES_NEW_ROMAN;
    // if (!this.element.lineHeight) this.element.lineHeight = 1.6;

    this.element.paragraphList?.forEach(paragraph => {
      if (!paragraph.elementType) paragraph.elementType = ElementType.TEXT_PARAGRAPH;
    });
  }

  //Make sure the config only keeps input compatible with the type of text chosen
  //When changing to a different type of text, reset incomparible info to defaults
  clearOtherParagraphStyleInput(){
    if (!this.isSimpleText()) this.caption.setValue('')
    if (!this.isAdvancedInlineText() && !this.isListText()) this.element.advancedList = []
    if (!this.isAnnotation()) {
      delete this.element.text
      delete this.element.annotation
    }
    if (!this.isParagraphs())  this.element.paragraphList = []
    if (!this.isLink()) {
      const {entryId} = this.element.link
      this.element.link = {
        entryId,
        elementType: ElementType.TEXT_LINK,
        caption: ''
      }
    }
   }

  isSimpleText(){
    let paragraphStyleId = this.paragraphStyle.value;
    if (paragraphStyleId === TextParagraphStyle.HEADLINE){ return true }
    if (paragraphStyleId === TextParagraphStyle.HEADLINE_SMALL){ return true }
    if (paragraphStyleId === TextParagraphStyle.REGULAR){ return true }
    if (paragraphStyleId === TextParagraphStyle.SMALL){ return true }
    return false;
  }

  isShowingAdvanced(){
    return !this.element.isAdvancedOptionsDisabled && this.isShowAdvancedOptions.value
  }

  isListText(){
    let paragraphStyleId = this.paragraphStyle.value;
    if (paragraphStyleId === TextParagraphStyle.BULLET){ return true }
    if (paragraphStyleId === TextParagraphStyle.NUMBERED){ return true }
    return false;
  }

  isParagraphs(){
    if (this.paragraphStyle.value === TextParagraphStyle.PARAGRAPHS){ return true }
    return false;
  }

  isAdvancedInlineText(){
    let paragraphStyleId = this.paragraphStyle.value;
    if (paragraphStyleId === TextParagraphStyle.ADVANCED_INLINE){ return true }
    return false;
  }

  isFontFamiliesEnabled(){
    return !this.whitelabel.getSiteFlag('IS_EQAO');
  }

  isLink(){
    let paragraphStyleId = this.paragraphStyle.value;
    if (paragraphStyleId === TextParagraphStyle.LINK){ return true }
    return false;
  }

  isAnnotation(){
    let paragraphStyleId = this.paragraphStyle.value;
    if (paragraphStyleId === TextParagraphStyle.ANNOTATION){ return true }
    return false;
  }

  removeElement(content:any[], element:any){
    if (window.confirm('Remove this part?')){
      let i = indexOf(content, element);
      if (i !== -1){
        content.splice(i, 1)
      }
    }
  }

  insertAdvancedListEntry(elementType:ElementType){
    if (!this.element.advancedList){
      this.element.advancedList = [];
    }
    this.element.advancedList.push(
      createDefaultElement(elementType)
    );
  }

  
  drop(arr:any, event: CdkDragDrop<string[]>) {
    // console.log('drop', arr) 
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
  }

  getIconByElementTypeId(elementTypeId:string){
    return elementIconById.get(elementTypeId);
  }

  getParagraphList(){
    if (!this.element.paragraphList){
      this.element.paragraphList = [];
    }
    return this.element.paragraphList;
  }

  addParagraph() {
    this.getParagraphList().push({
      elementType: ElementType.TEXT_PARAGRAPH
    })
  }

  isReadOnly() {
    return this.editingDisabled.isReadOnly();
  }

  setAlignment(align:string) {
    this.element.alignment = align;
  }
  
  addSimpleTextCode(newVal) {
    this.caption.setValue(newVal)
  }

  addCodeToParagraph(newVal, paragraph:IContentElementTextParagraph) {
    paragraph.caption = newVal
  }

  getTextId() {
    return "textBox"
  }

  getParElement(i:number) {
    if (this.paragraphs) {
      const arr = this.paragraphs.toArray()
      if (arr.length>i) {
        return this.paragraphs.toArray()[i].nativeElement
      }
    }
  }

  getTextElement() {
    if (this.textBox) {
      return this.textBox.nativeElement
    }
  }

  setTextFocus() {
    this.specialKeyboard.currentElement.next({element: this.element, htmlElement:this.textBox.nativeElement, formControl: this.caption})
  }

  setParagraphTextFocus(index) {
    this.specialKeyboard.currentElement.next({element: this.element.paragraphList[index], on: true, htmlElement:this.paragraphs._results[index].nativeElement, formControl: undefined})
  }

  focusOut() {
    this.specialKeyboard.focusOut()
  }
}
