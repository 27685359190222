import { IContentElementDynamicImage, ImageStates } from "../element-render-image/model";
import { IContentElementMcq, IContentElementMcqOption } from "../element-render-mcq/model";
import { ElementType, IContentElementColorPanel } from "../models";
import { IElementTypeEditInfo } from './../models/index'

export interface IContentElementCustomMCQ extends IContentElementMcq {
    size?: number;
}

export interface IContentElementCustomMcqOption extends IContentElementMcqOption {
    dynamicImage: IContentElementDynamicImage;
    xBubble?: number;
    yBubble?: number;
    subtext?: Array<IContentElementCustomMcqOptionSubText>;
    currentState?: ImageStates;
}
  
export interface IContentElementCustomMcqOptionSubText {
    text: string;
    x: number;
    y: number;
    size: number;
    colourScheme?: IContentElementColorPanel;
}

export const customMcqEditInfo: IElementTypeEditInfo = {
    editExcludeFields: ['options'],
    superType: ElementType.MCQ
}

export const customMcqOptionEditInfo: IElementTypeEditInfo = {
    editExcludeFields: ['dynamicImage'],
    superType: ElementType.MCQ_OPTION
}