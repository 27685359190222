import { IContentElementMcqOption } from "../element-render-mcq/model";
import { ElementType, IContentElement } from "../models";
import { IElementTypeEditInfo } from './../models/index'

export interface IContentElementTextSelection extends IContentElement {
    texts: IContentElementSelectableText[];
    hasOutline?: boolean;
    isSelectionsLimited?: boolean;
    maxSelections?: number;
    width?: number;
    isParagraphMode?: boolean;
    paragraphs?: IContentElementSelectableText[][]
    spaceAfterParagrapgh?: number
}

export const selectTextEditInfo: IElementTypeEditInfo = {
    editExcludeFields: ['texts','paragraphs'],
    nonHiddenChangeLogFields: ['texts','paragraphs']
}

export interface IContentElementSelectableText extends IContentElementMcqOption {}

export const selectableTextEditInfo: IElementTypeEditInfo = {
  editExcludeFields: [],
  superType: ElementType.MCQ_OPTION
}