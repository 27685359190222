import { IContentElement, IEntryState } from "../models";
import { IElementTypeEditInfo } from './../models/index'

export interface IContentElementGraphics extends IContentElement {
    url?: string;
}

export interface IEntryStateGraphics extends IEntryState {
    state: any;
}

export const graphicsEditInfo: IElementTypeEditInfo = {
    nonHiddenChangeLogFields: ['url'],
    editExcludeFields: []
}