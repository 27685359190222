import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ContrastMode, IContentElementDynamicImage, IContentElementImage, IContentElementImageSubText, ImageStates } from 'src/app/ui-testrunner/element-render-image/model';
import { ElementType, FontFamily } from "../../ui-testrunner/models";
import { WhitelabelService } from '../../domain/whitelabel.service';
import { EditingDisabledService } from '../editing-disabled.service';
import { generateDefaultElementImage, generateDefaultFrameStateStyle, generateOldDefaultImage } from '../item-set-editor/models/index';
import { indexOf } from '../services/util';
import { getVoiceChange } from 'src/app/io-audio/capture-voice/capture-voice.component';
import { SpecialKeyboardService } from '../special-keyboard.service';

@Component({
  selector: 'element-config-image',
  templateUrl: './element-config-image.component.html',
  styleUrls: ['./element-config-image.component.scss']
})
export class ElementConfigImageComponent implements OnInit, OnDestroy {

  @Input() element:IContentElementDynamicImage;
  @Input() hasStates?:boolean
  @Input() isNoScale?:boolean=false
  @Input() canMove?: boolean=false
  @Input() showFrame?: boolean=true
  @Output() state = new EventEmitter<ImageStates>();
  @ViewChildren('subtextTA') subtextTAs
  @Output() change = new EventEmitter<null>();

  // task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  scale = new FormControl(100);
  scaleSelected = new FormControl(100);
  scaleHover = new FormControl(100);
  subtextInputs = []
  currentState;

  states = [
     {id: ImageStates.DEFAULT, caption: "Default" },
     {id: ImageStates.SELECTED, caption: "On Selection" },
     //{id: ImageStates.HOVERING, caption: "On Hover" }
  ]

  getVoiceChange = getVoiceChange;

  constructor(
    // private storage: AngularFireStorage,
    // private db: AngularFirestore,
    // private auth: AuthService,
    private sanitizer: DomSanitizer,
    private whitelabel: WhitelabelService,
    private editingDisabled: EditingDisabledService,
    private specialKeyboard: SpecialKeyboardService
  ) {}

  ngOnInit() {
    this.ensureConfigRules();
    this.currentState = ImageStates.DEFAULT;
    this.element.subtexts.forEach(subtext => this.subtextInputs.push(this.initSubtext(subtext)))
  }

  /**
  * Makes any necessary adjustments to `this.element` to follow specifications for the IMAGE element type
  * In case e.g. the question was created before some updates to the block creation code
  */
  ensureConfigRules(){
    if (!this.element.subtexts) this.element.subtexts = [];
    this.element.subtexts.forEach(subtext => this.subtextInputs.push(this.initSubtext(subtext)))
    this.currentState = ImageStates.DEFAULT;
    if (!this.element.images) this.element.images = {};
    if (!this.element.images[ImageStates.DEFAULT] && this.element.url) {
      this.element.images[ImageStates.DEFAULT] = generateOldDefaultImage(this.element, ImageStates.DEFAULT);
    }
    this.states.forEach((state) => {
      if (!this.element.images[state.id]) {
        this.element.images[state.id] = { condition: state.id, image: generateDefaultElementImage(ElementType.IMAGE) }
      }
      if (this.element.images[state.id].image.url && !this.element.images[state.id].image.altText) {
        this.element.images[state.id].image.altText = "";
      }
    })

    this.element.subtexts.forEach((sub)=>{
      // if (!sub.fontFamily) sub.fontFamily = FontFamily.HELVETICA;
      if (!sub.alignment) sub.alignment = this.alignments[0].id;
    })
    if (!this.element.XPosition) this.element.XPosition=0;
    if (!this.element.YPosition) this.element.YPosition=0;

    if (!this.element.frameState) this.element.frameState = generateDefaultFrameStateStyle();
    this.element.setRelative = this.canMove;

    if(!this.element.voiceover) {
      this.element.voiceover = {};
    }
  }

  isFontFamiliesEnabled(){
    return !this.whitelabel.getSiteFlag('IS_EQAO');
  }

  setScale(imgEl: IContentElementImage, scale:FormControl) {
    if (imgEl.scale){
      scale.setValue(imgEl.scale);
    }

    return scale.valueChanges.subscribe( e => {
      imgEl.scale = scale.value
    })
  }

  emitState() {
    this.state.emit(this.currentState)
    this.change.emit()
  }

  fontStyles = [
    {id: FontFamily.ARIAL, caption: "Arial"},
    {id: FontFamily.BIG_CASLON, caption: "Big Caslon"},
    {id: FontFamily.BODONI_MT, caption: "Bodoni MT"},
    {id: FontFamily.BOOKMAN, caption: "Bookman"},
    {id: FontFamily.BOOK_ANTIQUA, caption: "Book Antiqua"},
    {id: FontFamily.CALIBRI, caption: "Calibri"},
    {id: FontFamily.GILL_SANS, caption: "Gill Sans"},
    {id: FontFamily.HELVETICA, caption: "Helvetica"},
    {id: FontFamily.LUCIDA_SANS, caption: "Lucida Sans"},
    {id: FontFamily.NOTO, caption: "Noto"},
    {id: FontFamily.TIMES_NEW_ROMAN, caption: "Times New Roman"},
    {id: FontFamily.VERDANA, caption: "Verdana"},
  ]

  public alignments = [
    {id:'left', icon:'fa-align-left'},
    {id:'center', icon:'fa-align-center'},
    {id:'right', icon:'fa-align-right'},
  ]

  addSubText() {
    const newSubtext = {
      elementType: ElementType.IMAGE_SUBTEXT,
      text: "",
      x: 0,
      y: 0,
      size: 1,
      width: 0,
      invisible: 0,
      colourScheme: { backgroundColor: "#FFFFFF", textColor: "#000000" },
      fontFamily: null, // do not default to times new roman
      alignment: this.alignments[0].id
    }
    this.element.subtexts.push(newSubtext)
    this.subtextInputs.push(this.initSubtext(newSubtext))
  }

  deleteSubText(subtext:IContentElementImageSubText) {
    const i = indexOf(this.element.subtexts, subtext);
    if (i!=-1) {
      this.element.subtexts.splice(i,1);
      this.subtextInputs.splice(i,1);
    }
  }

  initSubtext(subtext:IContentElementImageSubText){
    const input = new FormControl(subtext.text);
    input.valueChanges.subscribe(obs => {
      subtext.text = input.value;
    })
    return input;
  }

  ngOnDestroy(){
  }

  isReadOnly() {
    return this.editingDisabled.isReadOnly();
  }

  getHiContrastImage() {
    if (!this.element.images[this.currentState].image.hiContrastImg) {
      this.element.images[this.currentState].image.hiContrastImg = generateDefaultElementImage('image')
    }
    return this.element.images[this.currentState].image.hiContrastImg
  }

  isInHiContrast() {
    return this.element.mode == ContrastMode.HIGH_CONTRAST
  }

  isNormal() {
    return this.element.mode == ContrastMode.NORMAL || this.element.mode == undefined
  }

  setSubtextFocus(i:number) {
    this.specialKeyboard.currentElement.next({element: this.element.subtexts[i], htmlElement:this.subtextTAs._results[i].nativeElement, formControl: this.subtextInputs[i]})
  }

  focusOut() {
    this.specialKeyboard.focusOut()
  }
}
