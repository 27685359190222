import { Component, OnInit, ViewChild, Input, ElementRef, EventEmitter, Output, SimpleChanges} from '@angular/core';
import { FormControl } from '@angular/forms';
import {ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import { Observable } from 'rxjs';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {map, startWith} from 'rxjs/operators';
import { LangService } from 'src/app/core/lang.service';

@Component({
  selector: 'chip-selector',
  templateUrl: './chip-selector.component.html',
  styleUrls: ['./chip-selector.component.scss']
})
export class ChipSelectorComponent <T> implements OnInit {

  @Input() potentialItems: T[];
  @Input() selectedItems: T[];
  @Input() renderItemOption: (item: T) => string;
  @Input() renderItemChip: (item: T) => string;
  @Input() filterItems:  (inputString: string | T, potentialItems: T[]) => T[];
  @Input() itemIdProp: string;
  @Input() caption: string;
  @Input() placeholder: string;
  @Input() isDisabled: boolean;

  @Output() selectionChanged = new EventEmitter();

  itemCtrl = new FormControl('');
  filteredItems: Observable<T[]>;
  separatorKeysCodes: number[] = [ENTER];
  @ViewChild('itemInput') itemInput: ElementRef<HTMLInputElement>;

  constructor(
    public lang: LangService
  ) {
    this.filteredItems = this.itemCtrl.valueChanges.pipe(
      startWith(null),
      map((value: string | T | null) => (value ? this.filterItems(value, this.potentialItems) : this.potentialItems.slice())),
    );
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.potentialItems && !changes.potentialItems.firstChange) {
      this.itemCtrl.setValue(null)
    }
  }

  /** Add the chip to item selection */
  selectItem(event: MatAutocompleteSelectedEvent): void {
    if(!this.selectedItems.find(i => i[this.itemIdProp] == event.option.value[this.itemIdProp])) {
      this.selectedItems.push(event.option.value);
      this.selectionChanged.emit();
    }
    this.itemInput.nativeElement.value = '';
    this.itemCtrl.setValue(null);
  }

  /** Clear input when pressing on separator key */
  clearInput(event: MatChipInputEvent): void {
    event.input.value = '';
    this.itemCtrl.setValue(null);
  }

  /** Remove the chip from item selection */
  removeItem(item: T): void {
    if (this.isDisabled) return;
    const index = this.selectedItems.indexOf(item);
    if (index >= 0) {
      this.selectedItems.splice(index, 1);
      this.selectionChanged.emit();
    }
  }

}
