import { IContentElement } from "../models";
import { IElementTypeEditInfo } from './../models/index'

export interface IContentElementResultsPrint extends IContentElement {
    content: IContentElement[];
    inputEntryId?: number;
    isPlainText?:boolean;
}

export const resultsPrintEditInfo: IElementTypeEditInfo = {
    editExcludeFields: ['content'],
    nonHiddenChangeLogFields: ['content']
}